<template>
  <div>
    <!-- Listagem dos Grupos -->
    <b-col style="width: 100%">
      <b-card>
        <div class="card-header">
          <h4 class="card-title">
            Histórico de Pagamentos
          </h4>
          <div class="d-flex align-items-center">
            <p class="card-text font-small-2 mr-25 mb-0">
              Atualizado a 1m atrás
            </p>
          </div>
        </div>
        <div
          v-if="groupay"
          class="col-lg-12 col-12"
        >
          <div class="col-lg-12 col-12">
            <div class="card card-company-table">
              <div class="card-body p-0">
                <div class="table-responsive">
                  <table
                    id="example"
                    class="table"
                  >
                    <thead>
                      <tr>
                        <th>GRUPO</th>
                        <th>LINK DO GRUPO</th>
                        <th>ID PAGAMENTO</th>
                        <th>STATUS</th>
                      </tr>
                    </thead>
                    <tbody
                      v-for="groupays in groupay"
                      :key="groupays.id"
                    >
                      <tr>
                        <td>
                          <div class="d-flex align-items-center">
                            <div class="avatar rounded">
                              <div class="avatar-content">
                                <img
                                  src="@/assets/images/icons/star.svg"
                                  alt="Star svg"
                                >
                              </div>
                            </div>
                            <div>
                              <div class="font-weight-bolder">
                                {{ groupays.name }}
                              </div>
                              <div class="font-small-2 text-muted">
                                {{ groupays.type }}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex align-items-center">
                            <a
                              target="_bank"
                              :href="groupays.link"
                            >{{ groupays.link }}</a>
                          </div>
                        </td>
                        <td class="text-nowrap">
                          <div class="d-flex flex-column">
                            <span class="font-weight-bolder mb-25">
                              <span
                                class="font-weight-bolder mb-25"
                              >
                                <b-badge
                                  v-if="groupays.paymentid"
                                  variant="info"
                                >
                                  {{ groupays.paymentid }}
                                </b-badge>
                                <b-badge
                                  v-else
                                  variant="dark"
                                >
                                  SEM PAGAMENTO
                                </b-badge>
                              </span>
                            </span>
                          </div>
                        </td>
                        <td class="text-nowrap">
                          <div class="d-flex flex-column">
                            <span class="font-weight-bolder mb-25">
                              <b-badge
                                v-if="groupays.status == 'pendente'"
                                variant="warning"
                              >
                                {{ groupays.status.toUpperCase() }}
                              </b-badge>
                              <b-badge
                                v-if="groupays.status == 'aprovado_ext'"
                                variant="success"
                              >
                                PAGO
                              </b-badge>
                              <b-badge
                                v-if="groupays.status == 'aprovado'"
                                variant="success"
                              >
                                PAGO
                              </b-badge>
                              <b-badge
                                v-if="groupays.status == 'recusado'"
                                variant="danger"
                              >
                                {{ groupays.status.toUpperCase() }}
                              </b-badge>
                              <b-badge
                                v-if="groupays.status == 'banido'"
                                variant="dark"
                              >
                                {{ groupays.status.toUpperCase() }}
                              </b-badge>
                              <b-badge
                                v-if="groupays.status == 'vencido'"
                                variant="danger"
                              >
                                {{ groupays.status.toUpperCase() }}
                              </b-badge>
                              <b-badge
                                v-if="groupays.status == 'analise'"
                                variant="warning"
                              >
                                {{ groupays.status.toUpperCase() }}
                              </b-badge>
                            </span>
                          </div>
                        </td>

                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- Modal View -->
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <b-alert
            show
            variant="primary"
          >
            <div class="alert-body">
              <feather-icon
                class="mr-25"
                icon="StarIcon"
              />
              <span
                class="ml-25"
              >Voce nao possui nenhum pagamento encontrado.</span>
            </div>
          </b-alert>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            :to="{ name: 'cadastrar-grupo' }"
          >
            Cadastrar Grupo
          </b-button>
        </div>
      </b-card>
    </b-col>
    <!-- Listagem dos Grupos -->
  </div>
</template>

<script>
import {
  BCard, BCol, BAlert, BBadge,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import funcs from '@/services/func.service'
import moment from 'moment'

const user = JSON.parse(localStorage.getItem('user'))

export default {
  components: {
    BAlert,
    BCard,
    BBadge,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      stickyHeader: true,
      groupay: [],
    }
  },
  mounted() {
    this.fetchUserPaymentHistories()
  },
  methods: {
    fetchUserPaymentHistories() {
      funcs.fetchUserPaymentHistories(user.id).then(success => {
        console.log(success.data)
        if (success.data) {
          this.groupay = success.data
        }
      })
    },
    formatDate(date) {
      const newdate = `${moment(date)
        .utc()
        .format('DD/MM/YYYY')}`
      this.date_end = newdate
      return newdate
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
</style>
